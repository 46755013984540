import React, { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { includes } from 'ramda';

import { useQueryClient } from '@tanstack/react-query';

import { useAuth, useSession } from '@clerk/nextjs';

import { useVerifyPermission } from '@@/Authentication/hooks';
import { CLERK_TEMPLATES } from '@@/Authentication/integration/clerk';
import { Store } from '@@/StoreAndStoreOperator/entities/store';
import { StoreOperator } from '@@/StoreAndStoreOperator/entities/store-operator';
import { useSecureStores, useStoreOperatorService } from '@@/StoreAndStoreOperator/hooks';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';
import { LOG_TYPE, useLogs } from '@/hooks/use-logs';

import { tradeInApi } from '@/config/api';

import { PUBLIC_ROUTES, ROUTES } from '@/constants';
import { fetchKeys } from '@/constants/fetch-keys';

export type UserData = Pick<StoreOperator, 'firstName' | 'lastName' | 'userId' | 'document' | 'id'>;

export type UserAuthContextData = {
  logout: () => void;
  userData?: UserData;

  storeData?: Store;
  saveStoreData: (store: Store) => void;

  isSignedIn?: boolean;
  cleanLegacyData: () => void;

  interceptorId: number | null;
};

const UserAuthContext = createContext<UserAuthContextData>({} as UserAuthContextData);

export const UserAuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { log } = useLogs();
  const router = useRouter();
  const queryClient = useQueryClient();
  const storage = useLocalStorage();
  const { isOnlyAdmin } = useVerifyPermission();

  const { isLoaded, isSignedIn: isClerkSignedIn, getToken, signOut, sessionId } = useAuth();
  const hasClerkToken = Boolean(isLoaded && isClerkSignedIn);

  const { isSignedIn: isSessionSignedIn } = useSession();

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [interceptorId, setInterceptorId] = useState<number | null>(null);

  const [userData, setUserData] = useState<UserData>(() => {
    return storage?.getItem(STORAGE_KEYS.DOJI_USER_DATA);
  });

  const [storeData, setStoreData] = useState<Store>(() => {
    return storage?.getItem(STORAGE_KEYS.SELECTED_STORE);
  });

  const { isStoreOperatorSuccess, storeOperatorMeData, refetchStoreOperator } = useStoreOperatorService();
  const {
    storeData: dataStore,
    isStoreSuccess,
    refetchStore,
  } = useSecureStores({
    enabled: Boolean(isClerkSignedIn && hasClerkToken && !!storeData?.id && isStoreOperatorSuccess && !isOnlyAdmin),
    refetchInterval: 15 * 1000,
  });

  const shouldBeLogged = !includes(router.pathname, PUBLIC_ROUTES);

  useEffect(() => {
    if (isOnlyAdmin) router.push(ROUTES.ONLY_ADMIN);
  }, [isOnlyAdmin]);

  useEffect(() => {
    if (isClerkSignedIn) {
      setIsSignedIn(true);
      return;
      //maximum lifetime session
    } else if (isLoaded && !isSessionSignedIn && !sessionId && shouldBeLogged) {
      logout();
    }
    setIsSignedIn(false);
  }, [isClerkSignedIn, sessionId, isSessionSignedIn]);

  useEffect(() => {
    if (storeOperatorMeData && isStoreOperatorSuccess) {
      const userData = {
        id: storeOperatorMeData.id,
        firstName: storeOperatorMeData.firstName,
        lastName: storeOperatorMeData.lastName,
        userId: storeOperatorMeData.userId,
        document: storeOperatorMeData.document,
      } as UserData;

      storage?.setItem(STORAGE_KEYS.DOJI_USER_DATA, userData);
      setUserData(userData);
    }
  }, [storeOperatorMeData, isStoreOperatorSuccess]);

  useEffect(() => {
    if (dataStore && isStoreSuccess) {
      const currentStore = dataStore.find((store) => store.id === storeData?.id);

      if (currentStore) {
        setStoreData(currentStore);
        storage?.setItem(STORAGE_KEYS.SELECTED_STORE, currentStore);
        return;
      }

      if (!isOnlyAdmin && router.pathname !== ROUTES.OPERATOR_PORTAL) {
        setStoreData({} as Store);
        storage?.removeItem(STORAGE_KEYS.SELECTED_STORE);
        router.push(ROUTES.OPERATOR_PORTAL);
      }
    }
  }, [dataStore, isStoreSuccess, storeData?.id]);

  const handleSetInterceptor = (store: Store) => {
    if (interceptorId) tradeInApi.interceptors.request.clear();

    const interceptor = tradeInApi.interceptors.request.use(async (req) => {
      if (hasClerkToken) {
        try {
          const newToken = await getToken({ template: CLERK_TEMPLATES.DEFAULT });
          req.headers['Authorization'] = `Bearer ${newToken}`;
        } catch (error) {
          log({ name: 'Error getting token', error: error as Error }, LOG_TYPE.ERROR);
        }
      }

      if (store) {
        req.headers['Doji-Store-Id'] = store.id;
        req.headers['Doji-Org-Id'] = store.orgId;
      }

      return req;
    });

    setInterceptorId(interceptor);
  };

  useEffect(() => {
    handleSetInterceptor(storeData);

    if (hasClerkToken && !isOnlyAdmin) {
      refetchStoreOperator();
      refetchStore();
    }
  }, [storeData?.id, hasClerkToken]);

  const cleanLegacyData = () => {
    tradeInApi.defaults.headers['Authorization'] = '';

    setUserData({} as UserData);
    setStoreData({} as Store);
    setIsSignedIn(false);

    //persist data tip even in logout
    const lastViewed = storage?.getItem(STORAGE_KEYS.DAILY_TIP_LAST_VIEWED);
    const phonePhotosModal = storage?.getItem(STORAGE_KEYS.PHONE_PHOTO_INSTRUCTIONS_MODAL);

    storage?.clearStorage();

    //persist data tip even in logout
    storage?.setItem(STORAGE_KEYS.DAILY_TIP_LAST_VIEWED, { ...lastViewed });
    storage?.setItem(STORAGE_KEYS.PHONE_PHOTO_INSTRUCTIONS_MODAL, { ...phonePhotosModal });

    queryClient.invalidateQueries({ queryKey: [fetchKeys.store.authStoreData, fetchKeys.user.data] });
    queryClient.clear();
  };

  const saveStoreData = (store: Store) => {
    if (hasClerkToken) handleSetInterceptor(store);

    setStoreData(store);
    storage?.setItem(STORAGE_KEYS.SELECTED_STORE, store);
  };

  const logout = async () => {
    const hasClerk = hasClerkToken;

    queryClient.invalidateQueries({ queryKey: [fetchKeys.user.data] });

    cleanLegacyData();

    if (hasClerk) {
      if (interceptorId) {
        tradeInApi.interceptors.request.eject(interceptorId);
      }

      await signOut({ redirectUrl: ROUTES.LOGIN });

      return;
    }
    if (router.pathname !== ROUTES.LOGIN) router.replace(ROUTES.LOGIN, undefined, { shallow: true });
  };

  const value = {
    isSignedIn,
    logout,
    userData,
    storeData,
    saveStoreData,
    cleanLegacyData,
    interceptorId,
  };

  if (!isLoaded || (isSignedIn && interceptorId === null)) return null;

  return <UserAuthContext.Provider value={value}>{children}</UserAuthContext.Provider>;
};

export const useUserAuth = (): UserAuthContextData => {
  const context = useContext(UserAuthContext);
  return context;
};
