import { datadogLogs } from '@datadog/browser-logs';

export enum LOG_TYPE {
  INFO = 'info',
  ERROR = 'error',
  CRITICAL = 'critical',
}

type LogParams = {
  name: string;
  props?: object;
  error?: Error;
};

export const useLogs = () => {
  const log = (params: LogParams, type: LOG_TYPE) => {
    switch (type) {
      case LOG_TYPE.INFO:
        datadogLogs.logger.info(params.name, params.props);
        break;
      case LOG_TYPE.ERROR:
        datadogLogs.logger.error(params.name, params.props, params.error);
        break;
      case LOG_TYPE.CRITICAL:
        datadogLogs.logger.critical(params.name, params.props, params.error);
        break;

      default:
        break;
    }
  };

  return { log };
};
